import { getAccountId } from '@/lib/account'
import { HTTPError } from 'ky'
import { getSession } from 'next-auth/react'
import { makeRequest, type Props } from './core-fetch'

// type CustomClient<TData> = (data: Props) => Promise<TData>

export type ResponseConfig<TData = unknown> = TData

export default async function clientFetch<
  TData,
  TError = unknown,
  TVariables = unknown,
>(props: Props) {
  const session = await getSession()
  try {
    return makeRequest<TData>({
      getAccountId,
      getSession,
      session,
      ...props,
    })
  } catch (exception) {
    // throw exception as HTTPError<TError>
    if (exception instanceof HTTPError) {
      const error = await exception.response.json<TError>()
      throw error
    }
  }
}

// export const useClientFetch = <TData>(): CustomClient<TData> => {
//   const { data: session } = useSession()
//   return async (props) => {
//     return makeRequest<TData>({ getAccountId, getSession, session, ...props })
//   }
// }
